import toast from 'react-hot-toast';

const _api = (token) => {
    token = token || null;
    const api = {};
    function ifetch(data){
        const xhr = new XMLHttpRequest();
        xhr.open(data.method, data.url, false);
        xhr.setRequestHeader("authorization", 'Bearer ' + token);
        try {
            xhr.send(data.post);
            return JSON.parse(xhr.response);
        } catch(err) {
            toast.error('Unable to communicate with the Intrazr API. Please try again later or check the status page.');
            console.log("test");
            return err;
        }
    }
	
  api.getUser = () => {
		 /*fetch(new Request('https://beta.api.intrazr.com/v1/accounts/me', { method: 'get', headers: { 'authorization': 'Bearer ' + token } })).then((response) => {
			 response.json().then((json) => {
				 console.log(json);
			 });
		 })*/
	return ifetch({method: "GET", url: "https://api.intrazr.com/v1/accounts/me"});
  }
  
  api.getServices = () => {
      return ifetch({method: "GET", url: "https://api.intrazr.com/v1/cloud/services"});
  }
  
  api.getVersion = () => {
    try {
    	const apihome = ifetch({method: "GET", url: "https://api.intrazr.com/"});
    	return apihome.version;
    } catch(error) {
    	return error;
    }	
  }
  
  api.getToken = (apikey) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://api.intrazr.com/v1/auth/token", false);
    try {
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xhr.send(JSON.stringify({"apikey": apikey}));
	return JSON.parse(xhr.response);
    } catch(err) {
        toast.error('Unable to communicate with the Intrazr API. Please try again later or check the status page.');
        return err;
    }
  }
  return api;
}

export default _api;
