import React from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import _api from './utils/api';
import Router from "./components/router";
const api = _api(localStorage.getItem("token"));
const version = api.getVersion();
const user = api.getUser();
function fetchServices(){
        const _services = api.getServices();
        let services = [];
        for (let idx in _services) {
            const item = _services[idx];
            services.push(item);
        }
        localStorage.setItem("services", JSON.stringify(services));
}
fetchServices();

console.log("Connected to Intrazr API v"+version);
localStorage.setItem("api_version", version);
localStorage.setItem("user", JSON.stringify(user));
function App() {
  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;

